import { Button as MuButton, withStyles } from '@material-ui/core';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    button: {

    }
})


const Button = withStyles((theme) => ({
    root: {
        color: '#fff',
        borderRadius: '100px',
        fontWeight: 700,
        textTransform: 'capitalize',
        padding: '10px 40px',
        width: "auto",
        '&:hover': {
            boxShadow: '0px 4px 4px rgba(46, 155, 255, 0.5)',
            transform: 'scale(1.1)',
            transition: 'all 0.3s',
        }
    }
}))(MuButton);

export default Button;
